<script setup lang="ts">

const PRICING =  {
    img: '/images/flame-black-large.png',
    title: 'PRICING',
    text: '$50 per Adult <br> $30 per Child under 12  <br> $500.00 Minimum for all parties  <br> <br>  <span class="text-sm">*Price may vary by location. </span>',
    icon: 'i-mdi-dollar'
  }

  const PROTEIN_CHOICES = 
  {
    img: '/images/flame-black-large.png',
    title: 'PROTEIN CHOICES',
    text: '2 Per Person <br><br> <span class="text-md">Chicken, Steak, Shrimp, Scallops, Salmon, Tofu, Filet Mignon (+$5), Lobster Tail (+$10) </span> <br>  <br> <span class="text-sm"> Includes Salad, Fried Rice, and Vegetable </span>',
    icon: 'i-mdi-utensils'
  }
  const ADDONS = 
  {
    img: '/images/flame-black-large.png',
    title: 'ADD ONS & EXTRAS',
    text: 'Additional protein costs: <br> Chicken (+$10), Steak (+$10), Shrimp (+$10), Scallops (+$10), Salmon (+$10), Tofu (+$10), Filet Mignon (+$15), Lobster Tail (+$15), Gyoza - 6pcs (+$10), Edamame (+$5), Noodles (+$5), Bartender',
    icon: 'i-fluent-collections-add-20-filled'
  }

  const PREPARE =
  {
    img: '/images/flame-black-large.png',
    title: 'WHAT SHOULD YOU PREPARE',
    text: 'We bring the Chef, Cooking Setup, and Food. You provide Tables and Chairs for your party, positioned around where our chef will set up to cook. Also provide your guests Eating Utensils, Plates and salad bowls, and Beverages of choice. Please note we cook outside only.',
    icon: 'i-material-symbols-table-bar'
  }

</script>
<template>
  <SectionHeader title="HOW IT WORKS" desc="Our team brings the hibachi grill and a skilled chef directly to your place, whether it's a backyard or event venue. Just arrange the tables, chairs, and utensils for your guests. Then, enjoy as our chef dazzles your party with an exciting cooking show and serves a scrumptious meal." />
<!-- 
  <SectionHeader title="HOW IT WORKS" desc="Our team brings the hibachi grill and chef directly to your location, be it a backyard or any other event space.
        You simply need to set up tables, chairs, and ensure that dining utensils are available for your guests. Watch
        as our chef delights your party with an engaging cooking show, serving up a sumptuous meal everyone will enjoy." /> -->
    
  <section class="container mx-auto px-4">
    <div class="-mx-4 flex flex-wrap justify-center ">
        <HowItWorksCard :text="PRICING.text" :img="PRICING.img" :title="PRICING.title" :icon="PRICING.icon" />
        <HowItWorksCard :text="PROTEIN_CHOICES.text" :img="PROTEIN_CHOICES.img" :title="PROTEIN_CHOICES.title" :icon="PROTEIN_CHOICES.icon" />
        <HowItWorksCard :text="ADDONS.text" :img="ADDONS.img" :title="ADDONS.title" :icon="ADDONS.icon" />
    </div>
  

  </section>
</template>
<style scoped></style>
